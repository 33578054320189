import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerEmpleado() {
    var url = ConfigAPI.baseURL + "employee/employee" + Session.getToken();
    return instance.get(url);
  },
  mostrarEmpleado(id) {
    var url = ConfigAPI.baseURL + "employee/employee/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarEmpleado(data) {
    var params = data
    var url = ConfigAPI.baseURL + "employee/employee" + Session.getToken();
    return instance.post(url,params);
  },
  editarEmpleado(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "employee/employee/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarEmpleado(id) {
    var url = ConfigAPI.baseURL + "employee/employee/"+ id + Session.getToken();
    return instance.delete(url);
  },


  obtenerCategoria() {
    var url = ConfigAPI.baseURL + "employee/category" + Session.getToken();
    return instance.get(url);
  },
  mostrarCategoria(id) {
    var url = ConfigAPI.baseURL + "employee/category/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarCategoria(data) {
    var params = data
    var url = ConfigAPI.baseURL + "employee/category" + Session.getToken();
    return instance.post(url,params);
  },
  editarCategoria(data) {
    var params = data
    var url = ConfigAPI.baseURL + "employee/category/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCategoria(id) {
    var url = ConfigAPI.baseURL + "employee/category/"+ id + Session.getToken();
    return instance.delete(url);
  },

  obtenerHorasByEmployee(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/hours/filter/employee" + Session.getToken();
    return instance.post(url,params);
  }, 
  obtenerHorasByDia(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/hours/filter/day" + Session.getToken();
    return instance.post(url,params);
  },     
  obtenerHorasByMes(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/hours/filter/month" + Session.getToken();
    return instance.post(url,params);
  },  
}

export default servicesAPI;
